import React from 'react';
import Modal from './modal/modal';

function App() {
  return (
    <div>
      <Modal hiddeHeadline={false} showModal={false} />
    </div>
  );
}

export default App;
