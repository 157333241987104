import React, { useState, useEffect } from "react";
import "./style.css";
import espadilhaIcon from "../images/espadilha-icon.svg";
import logo from "../images/cassinopix-logo.svg";
import Card from "../scratch/Card";
import styled from "styled-components";

interface ModalProps {
    showModal: boolean;
    hiddeHeadline: boolean;
}

const Headline = styled.h1`
    margin: 15px 10px;
    text-align: center;
    font-family: "Gotham-Medium";
    font-size: 25px;
    color: white;
`

const CardComponent = styled.div`
    display: block;
    border: transparent;
  
`

const ModalComponent = styled.div`
    background-color: #232426;
    padding: 20px 10px;
    border-radius: 8px;
`

const Modal: React.FC<ModalProps> = ({ showModal, hiddeHeadline }) => {
    const [modal, setModal] = useState<boolean>(false);
    const [scratch, setScratch] = useState<boolean>(true);

    useEffect(() => {
        if (showModal) {
            setModal(true);
            setScratch(false);
        }
    }, [showModal]);

    return (
        <div id="overlay">
            <iframe
                src="https://cassinopix.com/"
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    opacity: 1,
                    filter: "blur(1.5px)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1000,
                }}
            ></iframe>
            <div id="modal" style={{ zIndex: 1001 }}>
                {modal && (
                    <ModalComponent>
                        <Headline>
                            <span style={{ color: '#4784ED', fontSize: '35px' }}>BOAAA!</span> <br />
                            Você ganhou até <br /> 100 rodadas grátis!
                        </Headline>
                        <div className="modal-content">
                            <a href={`https://go.aff.7k-partners.com/8btt5nxi?utm_campaign=mcgui`}>
                                <div className="container">
                                    <img src={espadilhaIcon} alt="Espadilha Icon" />
                                    20 rodadas grátis | Deposite R$10
                                </div>
                            </a>
                            <a href={`https://go.aff.7k-partners.com/8btt5nxi?utm_campaign=mcgui`}>
                                <div className="container">
                                    <img src={espadilhaIcon} alt="Espadilha Icon" />
                                    40 rodadas grátis | Deposite R$20
                                </div>
                            </a>
                            <a href={`https://go.aff.7k-partners.com/8btt5nxi?utm_campaign=mcgui`}>
                                <div className="container">
                                    <img src={espadilhaIcon} alt="Espadilha Icon" />
                                    100 rodadas grátis | Deposite R$50
                                </div>
                            </a>

                            <div className="btn">
                                <a href={`https://go.aff.7k-partners.com/8btt5nxi?utm_campaign=mcgui`}>DEPOSITAR</a>
                                <button onClick={() => window.location.href = `https://go.aff.7k-partners.com/8btt5nxi?utm_campaign=mcgui`}>Fechar</button>
                            </div>
                        </div>
                        <div>
                            <img id="logo" src={logo} alt="CassinoPix Logo" />
                        </div>
                    </ModalComponent>
                )}
                {scratch && (
                    <CardComponent>
                        <Card />
                    </CardComponent>
                )}
            </div>
        </div>
    );
};

export default Modal;
